function populateData(el, theData, parent) {
    let theParent = document?.querySelector(parent);
    let theElement = document?.querySelector(el);
    if (theElement && theParent) {
        theElement.innerHTML = "";

        if (theData) {
            if (theParent) {
                theParent.style.display = "flex";
            } else {
                theElement.style.display = "flex";
            }
            theElement.innerHTML = theData;
        } else {
            if (theParent) {
                theParent.style.display = "none";
            } else {
                theElement.style.display = "none";
            }
        }
    }
}

function createGallery(el, theData, parent) {
    let theParent = document?.querySelector(parent);
    let theElement = document?.querySelector(el);
    theElement.innerHTML = "";

    if (theData) {
        theData.forEach((data) => {
            var galleryEl = `<a class="glightbox" href="${data.saleVehicleImage.full}" data-gallery="gallery1"><img src="${data.saleVehicleImage.thumbnail}" /></a>`;
            theElement.insertAdjacentHTML("beforeend", galleryEl);
        });
    }
}

export { populateData, createGallery };
