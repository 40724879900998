import { removeAllClasses } from "./helpers";

const SHOWCASE_ITEMS = document.querySelectorAll(".vehicle-block");

SHOWCASE_ITEMS.forEach((item) => {
    let close_button = item.querySelector(".close-button");
    let open_button = item.querySelector(".open-desc");
    open_button.addEventListener("click", () => {
        removeAllClasses(".vehicle-block", "selected");
        item.classList.add("selected");
        item.scrollIntoView();
    });
    close_button.addEventListener("click", () => {
        removeAllClasses(".vehicle-block", "selected");
    });
});
