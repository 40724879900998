const item = document?.querySelector('.stats');
const btn = document?.querySelector('.stats-btn');
let btnText = btn?.firstElementChild.innerText;

const setHalf = () => {
    // add half class when window size below 768px
    let mql = window.matchMedia('(min-width: 768px)');
    if(mql.matches === true) {
        item?.classList.remove('half');
    } else {
        item?.classList.add('half');
    }

    // adjust button for closure on resize
    if (btnText && btnText !== 'View All') {
        btnText = 'View All';
        btn.firstElementChild.innerText = btnText;
    }
}

window.addEventListener('load', setHalf);
window.addEventListener('resize', setHalf);


btn?.addEventListener('click', () => {
    //add class to list to hide elements 5+
    item?.classList.toggle('half');

    //change btn text
    btnText === 'View All' ? btnText = 'View Less' : btnText = 'View All';
    btn.firstElementChild.innerText = btnText;
});