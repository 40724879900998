import {populateData, createGallery} from './populate-data';
import GLightbox from 'glightbox';



function getSalesVehicle(id, siteUrl) {
  document?.querySelector('.sales-vehicle-selection-step-two').classList.add('open');
  
  fetch(`${siteUrl}/graphql`, {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
      },
      body: JSON.stringify({
          query: `
          query getSalesVehicle {
            salesVehicle(id: "${id}", idType: DATABASE_ID) {
              title
              featuredImage {
                node {
                  sourceUrl
                }
              }
              salesVehicleDetails {
                saleVehicleDescription
                saleVehicleEngineSize
                saleVehicleFuelType
                saleVehicleMileage
                saleVehiclePrice
                saleVehicleYear
                saleVehicleGallery {
                  saleVehicleImage {
                    full: sourceUrl
                    thumbnail: sourceUrl(size: THUMBNAIL)
                  }
                }
              }
            }
          }
      `,
      }),
  })
      .then((res) => res.json())
      .then((result) => {
          var theData = result.data.salesVehicle;
          var galleryImages = theData.salesVehicleDetails.saleVehicleGallery;

          populateData('#sales-vehicle-name', theData.title);
          populateData('#sales-vehicle-description', theData.salesVehicleDetails.saleVehicleDescription, '.sales-vehicle-description');
          
          document.querySelector('#sales-vehicle-image').src = theData.featuredImage.node.sourceUrl;
          document.querySelector('#sales-vehicle-image-href').href = theData.featuredImage.node.sourceUrl;

          let gallery = theData.salesVehicleDetails.saleVehicleGallery;

          populateData('#sale-vehicle-price', theData.salesVehicleDetails.saleVehiclePrice, '.sales-vehicle-price');

          populateData('#sales-vehicle-mileage', theData.salesVehicleDetails.saleVehicleMileage, '.sales-vehicle-mileage');
          populateData('#sales-vehicle-fuel', theData.salesVehicleDetails.saleVehicleFuelType, '.sales-vehicle-fuel');
          populateData('#sales-vehicle-engine', theData.salesVehicleDetails.saleVehicleEngineSize, '.sales-vehicle-engine-size');
          populateData('#sales-vehicle-year', theData.salesVehicleDetails.saleVehicleYear, '.sales-vehicle-year');
          
          
          createGallery('#sales-vehicle-gallery', galleryImages, '#sales-vehicle-gallery');

          const lightbox = GLightbox({
            touchNavigation: true,
            closeButton: true
        });


      });     
}


export {getSalesVehicle};