const btns = document?.querySelectorAll('.directional-btn');
const timeline = document?.querySelector('.timeline-container');

btns.forEach(btn => {
    btn.addEventListener('click', () => {
        console.log(btn.firstElementChild.classList.contains('fa-angle-left'));
        if(btn.firstElementChild.classList.contains('fa-angle-left')){
            timeline.scrollBy({top: 0, left: -400, behavior: 'smooth'});
        } else {
            timeline.scrollBy({top: 0, left: 400, behavior: 'smooth'});
        }
    })
})