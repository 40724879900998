import { easepick, TimePlugin, LockPlugin } from "@easepick/bundle";
import dayjs from "dayjs";
const weekday = require("dayjs/plugin/weekday");
var isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
dayjs.extend(isSameOrAfter);
dayjs.extend(weekday);
const QQApp = document?.location.href;
var url = window.location.origin;

let quickquote_input = document.querySelector(".quick-quote-price .ginput_container input");

//set quick quote prcie field to read only so it can't be manually edited
if (quickquote_input) {
    quickquote_input.readOnly = true;
}

function addVehicleTypes() {
    if (document.querySelector(".category-quick-quote-form") || document.querySelector(".quick-quote-form")) {
        let quickQuoteSelectDiv = document?.querySelector("#quick-quote-vehicles");

        fetch(`${url}/graphql`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({
                query: `
        query getVehicleTypes {
            vehicles(first: 100, where: {orderby: {field: MENU_ORDER, order: ASC}}) {
                edges {
                    node {
                        databaseId
                        title
                    }
                }
            }
        }
    `,
            }),
        })
            .then((res) => res.json())
            .then((result) => {
                let vehicles = result.data.vehicles.edges;
                let vehicleID = result.data.vehicles;
                let selectElement;

                vehicles.forEach((vehicle) => {
                    selectElement += `<option value="${vehicle.node.databaseId}" class="quick-quote-type-option">${vehicle.node.title}</option>`;
                });
                quickQuoteSelectDiv.innerHTML = selectElement;
            });
    }
}

if (document.querySelector(".category-quick-quote-form") || document.querySelector(".quick-quote-form")) {
    let vehicleTypeField = document?.querySelector("#quick-quote-vehicles");
    vehicleTypeField?.addEventListener("change", function (e) {
        e.preventDefault();

        // document?.querySelectorAll(".quick-quote-type-option").forEach((option) => {
        //     document?.querySelector(".quick-quote-price").setAttribute("value", "Loading...");
        calculateQQPrice(FROM_DATE.getDate(), TO_DATE.getDate(), QQApp);
        //});
    });

    //init date time picker
    const TOMORROW = dayjs().add(1, "day").hour(9).minute(0).toDate();
    const DAY_AFTER_TOMMORROW = dayjs().add(2, "day").hour(9).minute(0).toDate();
    const FROM_DATE = new easepick.create({
        element: document.querySelector(".from-date-picker input"),
        css: [
            "https://cdn.jsdelivr.net/npm/@easepick/core@1.2.0/dist/index.css",
            "https://cdn.jsdelivr.net/npm/@easepick/time-plugin@1.2.0/dist/index.css",
            "https://cdn.jsdelivr.net/npm/@easepick/lock-plugin@1.2.0/dist/index.css",
        ],
        zIndex: 10,
        format: "DD/MM/YY HH:mm",
        autoApply: false,
        plugins: [TimePlugin, LockPlugin],
        TimePlugin: {
            stepMinutes: 10,
        },
        LockPlugin: {
            minDate: TOMORROW,
        },
        setup(picker) {
            picker.on("select", (e) => {
                const { view, date, target } = e.detail;
                let next_day = dayjs(date).add(1, "day").hour(9).minute(0).toDate();
                document?.querySelector(".quick-quote-price").setAttribute("value", "Loading...");
                TO_DATE.setDate(next_day);
                calculateQQPrice(FROM_DATE.getDate(), TO_DATE.getDate(), QQApp);
            });
        },
    });

    const TO_DATE = new easepick.create({
        element: document?.querySelector(".to-date-picker input"),
        css: [
            "https://cdn.jsdelivr.net/npm/@easepick/core@1.2.0/dist/index.css",
            "https://cdn.jsdelivr.net/npm/@easepick/time-plugin@1.2.0/dist/index.css",
            "https://cdn.jsdelivr.net/npm/@easepick/lock-plugin@1.2.0/dist/index.css",
        ],
        zIndex: 10,
        format: "DD/MM/YY HH:mm",
        autoApply: false,
        plugins: [TimePlugin, LockPlugin],
        TimePlugin: {
            stepMinutes: 10,
        },
        LockPlugin: {
            minDate: DAY_AFTER_TOMMORROW,
        },
        setup(picker) {
            picker.on("select", (e) => {
                calculateQQPrice(FROM_DATE.getDate(), TO_DATE.getDate());
            });
        },
    });

    FROM_DATE.setDate(TOMORROW);
    TO_DATE.setDate(DAY_AFTER_TOMMORROW);

    // PROLEMS WITH MULTIPLE FETCH FUNCTION RUNNING - TRY USING PROMISES and Promise.all
    function calculateQQPrice(fromDate, toDate) {
        let priceInputField = document.querySelector(".quick-quote-price .ginput_container input");
        let loadingIcon = document.querySelector(".loader");
        priceInputField.style.display = "none";
        loadingIcon.style.display = "block";
        const vehicleID = document?.querySelector("#quick-quote-vehicles").value;
        const theFromDate = dayjs(fromDate);
        let fromTime = dayjs(fromDate).format("HH:mm");
        const theToDate = dayjs(toDate);
        let toTime = dayjs(toDate).format("HH:mm");

        let numberOfdays = theToDate.diff(theFromDate, "day", true);

        numberOfdays = Math.ceil(numberOfdays);

        fetch(`${url}/graphql`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({
                query: `
            query getVehicle {
                vehicle(id: "${vehicleID}", idType: DATABASE_ID) {
                title
                vehicleDetails {
                    vehicleFsPrice
                    vehicleStPrice
                    vehicleWeekendRate
                    vehicleWeeklyRate
                }
                }
            }
        `,
            }),
        })
            .then((res) => res.json())
            .then((result) => {
                var theData = result.data.vehicle;
                var vehicleWeekendPrice = theData.vehicleDetails.vehicleWeekendRate;
                var vehicleWeeklyPrice = theData.vehicleDetails.vehicleWeeklyRate;
                var vehicleFridaySaturdayPrice = theData.vehicleDetails.vehicleFsPrice;
                var vehicleSundayThursdayPrice = theData.vehicleDetails.vehicleStPrice;
                var vehicleTitle = theData.title;

                console.log(result);

                let price = 0;
                // calculate the price
                if (dayjs(theFromDate).weekday() === 5 && dayjs(fromTime).isSameOrAfter("17:00", "hour") && dayjs(theFromDate).weekday() === 1 && dayjs(fromTime).isBefore("9:00", "hour")) {
                    //chack if it is solely the weekend
                    price = parseFloat(vehicleWeekendPrice) * numberOfdays;
                } else if (numberOfdays >= 7) {
                    //otherwise check it is 7 days or longer
                    let newDayRate = parseFloat(vehicleWeeklyPrice) / 7;
                    price = newDayRate * numberOfdays;
                } else {
                    //if it is not any of the above cases, get each days price
                    var total = 0;
                    for (let x = 1; x <= numberOfdays; x++) {
                        let thisDate = dayjs(theFromDate).add(x, "day");
                        let thisDay = dayjs(thisDate).weekday();
                        if (thisDay === 5 || thisDay === 6) {
                            total += parseFloat(vehicleFridaySaturdayPrice);
                        } else {
                            total += parseFloat(vehicleSundayThursdayPrice);
                        }
                    }

                    price = total;
                }

                document.querySelector(".vehicle-type-admin .ginput_container input").setAttribute("value", vehicleTitle);

                if (price == 0.0) {
                    document?.querySelector(".quick-quote-price .ginput_container input").setAttribute("value", "Unable to calculate price. Please try again.");
                    document.querySelector(".quick-quote-price .ginput_container input").value = "Unable to calculate price. Please try again.";
                } else {
                    // Add the calculated price into the field
                    document?.querySelector(".quick-quote-price .ginput_container input").setAttribute("value", "£" + price.toFixed(2));
                    document.querySelector(".quick-quote-price .ginput_container input").value = "£" + price.toFixed(2);
                }

                loadingIcon.style.display = "none";
                priceInputField.style.display = "block";
            });
    }
}

export { addVehicleTypes };
