import Splide from "@splidejs/splide";

if(document.querySelector('.splide')) {
    new Splide(".splide", {
        type: "loop",
        arrowPath:
            "m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z",
        autoplay: false,
        interval: 3000,
        arrows: false,
    }).mount();
}

if(document.querySelector('.charity-splide')) {
    new Splide(".charity-splide", {
        type: "loop",
        arrowPath:
            "m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z",
        autoplay: true,
        interval: 3000,
        arrows: false,
        perPage: 5,
        breakpoints: {
            768: {
                perPage: 2,
            },
            480: {
                perPage: 1,
            }
        }
    }).mount();
}

