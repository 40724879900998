import { offCanvasOpen, offCanvasClose, offcanvasDropdown } from "./modules/offcanvas";

import "./modules/slider";

import "./modules/glightbox-config";

import "./modules/stats-reveal";

import "./modules/accordion";

import "./modules/timeline-slider";

import "./modules/family-profile-open";

import "./modules/showcase-selection";

import "../css/main-style.css";

import { getVehicle } from "./modules/vehicle-selection";

import { addVehicleTypes } from "./modules/quick-quote";

import { getSalesVehicle } from "./modules/sales-vehicle-selection";

offCanvasOpen();
offCanvasClose();
offcanvasDropdown();

const QQApp = document?.location.href;

addVehicleTypes(QQApp);

const APP = document?.querySelector("#vehicle-app");

let vehicleID;

document?.querySelectorAll(".vehicle").forEach((vehicle) => {
    vehicle?.addEventListener("click", function (e) {
        e.preventDefault();

        var element = document?.getElementById("section-two");

        document?.querySelectorAll(".vehicle").forEach((vehicle) => {
            vehicle.classList.remove("active-vehicle");
        });

        vehicleID = parseInt(vehicle.getAttribute("data-id"));
        getVehicle(vehicleID, APP.dataset.siteUrl);

        vehicle.classList.add("active-vehicle");
        element.scrollIntoView();
    });
});

const SalesAPP = document?.querySelector("#sales-vehicle-app");

document?.querySelectorAll(".sales-vehicle").forEach((salesVehicle) => {
    salesVehicle?.addEventListener("click", function (e) {
        e.preventDefault();

        var element = document?.getElementById("section-two");

        document?.querySelectorAll(".sales-vehicle").forEach((salesVehicle) => {
            salesVehicle.classList.remove("active-vehicle");
        });

        vehicleID = parseInt(salesVehicle.getAttribute("data-id"));
        getSalesVehicle(vehicleID, SalesAPP.dataset.siteUrl);

        salesVehicle.classList.add("active-vehicle");
        element.scrollIntoView();
    });
});
const QuickQuote = document?.querySelector("#quick-quote-vehicles");
