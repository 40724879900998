const items = document.querySelectorAll('.acc-item-header');

//set first item as active
items[0]?.closest('.acc-item').classList.add('active');

//add event listener to each item header
items?.forEach(item => {
    item.addEventListener('click', (e) => {
        //toggle show class
        e.target.closest('.acc-item').classList.toggle('active');
    })
})