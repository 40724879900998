import { populateData } from "./populate-data";

function getVehicle(id, siteUrl) {
    document?.querySelector(".vehicle-selection-step-two").classList.add("open");

    fetch(`${siteUrl}/graphql`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            query: `
            query getVehicle {
              vehicle(id: "${id}", idType: DATABASE_ID) {
                title
                content
                featuredImage {
                  node {
                    sourceUrl
                  }
                }
                vehicleDetails {
                  vehicleEstimate
                  vehicleFsPrice
                  vehicleHalfDayRate
                  vehicleStPrice
                  vehicleWeekendRate
                  vehicleWeeklyRate
                  vehicleImage {
                    sourceUrl
                  }
                }
                vehicleSpecifications {
                  betweenArches
                  co2
                  fieldGroupName
                  fuel
                  loadHeightFloorToRoof
                  loadLength
                  loadWidth
                  mpg
                  overallHeight
                  overallLength
                  payload
                  rearDoorLoadHeight
                  rearDoorLoadWidth
                  seatingCapacity
                }
              }
            }
        `,
        }),
    })
        .then((res) => res.json())
        .then((result) => {
            var theData = result.data.vehicle;

            populateData("#vehicle-name", theData.title);
            populateData("#vehicle-model", theData.vehicleDetails.vehicleEstimate);
            populateData("#vehicle-description", theData.content, ".vehicle-description");

            document.querySelector("#vehicle-image").src = theData.featuredImage.node.sourceUrl;

            populateData("#sun-thur-rate-from", theData.vehicleDetails.vehicleStPrice, ".day-rate-st-container");
            populateData("#sun-thur-rate", theData.vehicleDetails.vehicleStPrice, ".day-rate-st-container");
            // populateData('#fri-sat-rate', theData.vehicleDetails.vehicleFsPrice, '.day-rate-fs-container');
            populateData("#week-rate", theData.vehicleDetails.vehicleWeeklyRate, ".weekly-rate-container");
            populateData("#weekend-rate", theData.vehicleDetails.vehicleWeekendRate, ".weekend-rate-container");
            // populateData('#half-day-rate', theData.vehicleDetails.vehicleHalfDayRate, '.half-day-rate-container');

            populateData("#seating-capacity", theData.vehicleSpecifications.seatingCapacity, ".specification-1");
            populateData("#fuel", theData.vehicleSpecifications.fuel, ".specification-2");
            populateData("#load-width", theData.vehicleSpecifications.loadWidth, ".specification-3");
            populateData("#between-arches", theData.vehicleSpecifications.betweenArches, ".specification-4");
            populateData("#load-height-f-to-r", theData.vehicleSpecifications.loadHeightFloorToRoof, ".specification-5");
            populateData("#load-length", theData.vehicleSpecifications.loadLength, ".specification-6");
            populateData("#mpg", theData.vehicleSpecifications.mpg, ".specification-7");
            populateData("#rear-load-height", theData.vehicleSpecifications.rearDoorLoadHeight, ".specification-8");
            populateData("#rear-load-width", theData.vehicleSpecifications.rearDoorLoadWidth, ".specification-9");
            populateData("#payload", theData.vehicleSpecifications.payload, ".specification-10");
            populateData("#overall-length", theData.vehicleSpecifications.overallLength, ".specification-11");
            populateData("#overall-height", theData.vehicleSpecifications.overallHeight, ".specification-12");
            populateData("#co2", theData.vehicleSpecifications.co2, ".specification-13");

            let submit = document?.querySelector("#vehicle-selection-submit");
            submit.addEventListener("click", function (e) {
                document.getElementById("quick-quote-vehicles").value = id;
                document.getElementById("quick-quote-price").innerHTML = "Loading...";
            });
        });
}

export { getVehicle };
